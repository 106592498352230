import Axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import HighchartsMore from "highcharts/highcharts-more";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import { setselectedKey } from "../slices/mapView";

// Initialize the exporting and export-data modules.
exporting(Highcharts);
exportData(Highcharts);

function Graph(props) {
  const { loggedin } = useSelector((state) => state);
  const [data, setData] = useState(null);
  const {
    calcval,
    startmonth,
    endmonth,
    startyear,
    indicator,
    loading,
    error,
    calctype,
    selectedUnit,
    selectedDistrict,
    selectedTehsil,
    refresh,
    precipitation,
    months,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchMyAPI() {
      let unit, name;
      if (selectedTehsil) {
        unit = "tehsils";
        name = selectedTehsil;
      } else if (selectedDistrict) {
        unit = "districts";
        name = selectedDistrict;
      } else if (selectedUnit) {
        unit = "units";
        name = selectedUnit;
      }

      await Axios.post("../python/getdata", {
        params: {
          startmonth: startmonth,
          endmonth: endmonth,
          startyear: startyear,
          unit: unit,
          name: name,
          ...(indicator === "DrySpell" ? { precipitation: precipitation } : {}),
          ...(indicator === "DrySpell" ? {} : { aggr: calcval }),
          ...(indicator === "SPI_CHIRPS" || indicator === "SPI_ERA5L"
            ? { months: months }
            : {}),
          indice: indicator === "CWDI" ? "CWD" : indicator,
          calctype: calctype,
        },
      })
        .then(function (response) {
          if (response) {
            if (response.data.data.indexOf("Error") !== -1) {
              error(response.data.data);
            } else {
              setData(JSON.parse(response.data.data));
              loading(false);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (loggedin && indicator && refresh) {
      Axios.get("../backend/logincheck", {
        headers: {
          "access-token": localStorage.getItem("token"),
        },
      }).then((response) => {
        if (response.data.auth === true) {
          fetchMyAPI();
        } else dispatch(setselectedKey(null));
      });
    }
  }, [calcval, startmonth, endmonth, startyear, indicator, loggedin]);

  if (data) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (calcval === "default") {
    } else {
      var dates = [],
        intervals = [];
      data.mapid.map((e) => {
        // let d = e[1].replace("T00:00:00", "");
        indicator.indexOf("SPI") !== -1
          ? intervals.push(parseFloat(e[0].toFixed(2)))
          : e[0] < 0
          ? intervals.push(0)
          : intervals.push(parseFloat(e[0].toFixed(2)));
        dates.push(
          e[1]
          // d.substring(5, 7).charAt(0) === "0"
          //   ? monthNames[d.substring(5, 7) - 1] + " - " + d.substring(0, 4)
          //   : monthNames[d.substring(5, 7) - 1] + " - " + d.substring(0, 4)
        );
        return null;
      });

      const indicatorRanges = {
        SMCI: { min: -3, max: 3 },
        TCI: { min: -1, max: 1 },
        VCI: { min: -1, max: 1 },
        VHI: { min: -1, max: 1 },
        NDVI: { min: -1, max: 1 },
        MAI: { min: -1, max: 1 },
        CWD: { min: -1, max: 1 },
        PCI: { min: -1, max: 1 },
        SPI_CHIRPS: { min: -5, max: 5 },
        SPI_ERA5L: { min: -5, max: 5 },
        AETI: { min: 0, max: 100 },
        NPP: { min: 0, max: 100 },
        WAPOR_Prec: { min: 0, max: 200 },
        RRZSM: { min: 0, max: 200 },
        RET: { min: 0, max: 200 },
      };

      const { min, max } = indicatorRanges[indicator] || { min: 0, max: 0 };

      try {
        const options = {
          chart: {
            zoomType: "xy",
          },
          title: {
            text:
              indicator.toUpperCase() +
              " Timeseries " +
              dates[0] +
              " to " +
              dates[dates.length - 1],
          },
          yAxis: {
            title: {
              text: indicator.indexOf("SPI") !== -1 ? "Values" : "Values",
            },
            min: min,
            max: max,
          },
          xAxis: {
            categories: dates,
          },
          series: [
            {
              name: indicator.toUpperCase(),
              type: "line",
              data: intervals,
            },
          ],
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "separator",
                  "downloadCSV",
                  "downloadXLS",
                ],
              },
            },
          },
        };

        return <HighchartsReact highcharts={Highcharts} options={options} />;
      } catch (e) {}
    }
  } else {
    return "";
  }
}

export default Graph;
